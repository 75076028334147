import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Pagination } from '@/helpers/types';
import {
  Inventory,
  InventoryAdjustmentDetailReasons,
  InventoryList,
  MainInventory,
} from '@/helpers/types/inventoryTypes';
import {
  fetchAllInventoryData,
  fetchInventoryAdjustmentDetailReasons,
  fetchInventoryData,
  fetchInventoryItems,
  fetchMainInventoryData,
  fetchVanInventoryData,
  searchInventoryData,
  searchMainInventoryData,
  searchVanInventoryData,
} from '../thunk/inventoryThunk';

interface InventoryState {
  inventoryData: Inventory[];
  pagination: Pagination;
  mainInventoryData: MainInventory[];
  mainInventoryPagination: Pagination;
  vanInventoryData: MainInventory[];
  vanInventoryPagination: Pagination;
  allInventoryList: InventoryList[];
  inventoryItems: MainInventory[];
  inventoryAdjustmentDetailReasons: InventoryAdjustmentDetailReasons[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  team_member_full_name: string;
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: InventoryState = {
  inventoryData: [],
  pagination: {
    total_records: 0,
    current_records: 0,
    record_from: 0,
    record_to: 0,
    current_page: 0,
    total_pages: 0,
  },
  mainInventoryData: [],
  mainInventoryPagination: {
    total_records: 0,
    current_records: 0,
    record_from: 0,
    record_to: 0,
    current_page: 0,
    total_pages: 0,
  },
  vanInventoryData: [],
  vanInventoryPagination: {
    total_records: 0,
    current_records: 0,
    record_from: 0,
    record_to: 0,
    current_page: 0,
    total_pages: 0,
  },
  inventoryItems: [],
  allInventoryList: [],
  inventoryAdjustmentDetailReasons: [],
  team_member_full_name: '',
  status: 'idle',
  error: null,
};

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    clearInventories(state) {
      state.pagination = initialState.pagination;
      state.inventoryData = initialState.inventoryData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInventoryData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchInventoryData.fulfilled,
        (
          state,
          action: PayloadAction<{ data: Inventory[]; pagination: Pagination }>,
        ) => {
          state.inventoryData = action.payload.data;
          state.pagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchInventoryData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })

      // search inventory
      .addCase(searchInventoryData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        searchInventoryData.fulfilled,
        (
          state,
          action: PayloadAction<{ data: Inventory[]; pagination: Pagination }>,
        ) => {
          state.inventoryData = action.payload.data;
          state.pagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(searchInventoryData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })

      .addCase(fetchMainInventoryData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchMainInventoryData.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: MainInventory[];
            pagination: Pagination;
          }>,
        ) => {
          state.mainInventoryData = action.payload.data;
          state.mainInventoryPagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchMainInventoryData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })

      // search main inventory
      .addCase(searchMainInventoryData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        searchMainInventoryData.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: MainInventory[];
            pagination: Pagination;
          }>,
        ) => {
          state.mainInventoryData = action.payload.data;
          state.mainInventoryPagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(searchMainInventoryData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })

      .addCase(fetchVanInventoryData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchVanInventoryData.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: MainInventory[];
            pagination: Pagination;
            team_member_full_name: string;
          }>,
        ) => {
          state.vanInventoryData = action.payload.data;
          state.vanInventoryPagination = action.payload.pagination;
          state.team_member_full_name = action.payload.team_member_full_name;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchVanInventoryData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })

      // search main inventory
      .addCase(searchVanInventoryData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        searchVanInventoryData.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: MainInventory[];
            pagination: Pagination;
          }>,
        ) => {
          state.vanInventoryData = action.payload.data;
          state.vanInventoryPagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(searchVanInventoryData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })

      .addCase(fetchAllInventoryData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchAllInventoryData.fulfilled,
        (state, action: PayloadAction<{ data: InventoryList[] }>) => {
          state.allInventoryList = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchAllInventoryData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })

      .addCase(fetchInventoryItems.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchInventoryItems.fulfilled,
        (state, action: PayloadAction<{ data: MainInventory[] }>) => {
          state.inventoryItems = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchInventoryItems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })

      .addCase(fetchInventoryAdjustmentDetailReasons.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchInventoryAdjustmentDetailReasons.fulfilled,
        (
          state,
          action: PayloadAction<{ data: InventoryAdjustmentDetailReasons[] }>,
        ) => {
          state.inventoryAdjustmentDetailReasons = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(
        fetchInventoryAdjustmentDetailReasons.rejected,
        (state, action) => {
          state.status = 'failed';
          state.error = action.payload as string;
        },
      );
  },
});

export const { clearInventories } = inventorySlice.actions;
export default inventorySlice.reducer;
