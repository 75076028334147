'use client';
import React from 'react';
import StoreProvider from '@/redux/storeProvider';
import { ThemeProviderProps } from 'next-themes/dist/types';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import '../styles/globals.css';
import RouteLoader from '@/components/loading/RouteLoader';
import LocaleProvider from './LocaleProvider';

export interface ProvidersProps {
  children: React.ReactNode;
  themeProps?: ThemeProviderProps;
}

export function Providers({ children, themeProps }: ProvidersProps) {
  return (
    <StoreProvider>
      <LocaleProvider themeProps={themeProps}>
        <RouteLoader>{children}</RouteLoader>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          closeOnClick
          pauseOnHover
          draggable
          pauseOnFocusLoss
        />
      </LocaleProvider>
    </StoreProvider>
  );
}
