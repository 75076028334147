import { API_CUSTOMER_PORTAL, API_METHOD_GET } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { Method } from 'axios';
import { urlEncode } from '@/helpers/common';

export const SearchCustomerPortalJobsData = async (
  referenceKey: string,
  searchTerm: string,
  pageSize?: string,
  pagination?: number,
  selectedStatus?: string,
  startDate?: string | null,
  endDate?: string | null,
  selectedCustomer?: string,
) => {
  const method: Method = API_METHOD_GET;

  // Construct the base URL
  let url =
    `${API_CUSTOMER_PORTAL}/${referenceKey}/jobs?search=${urlEncode(searchTerm)}` +
    (pageSize && `&p-size=${pageSize}`) +
    (pagination && `&p=${pagination}`);

  if (selectedStatus) {
    url += `&f-status=${encodeURIComponent(`${selectedStatus}`)}`;
  }

  if (startDate && endDate) {
    url += `&f-start_date=${encodeURIComponent(`${startDate}:${endDate}`)}`;
    url += `&f-end_date=${encodeURIComponent(`${startDate}:${endDate}`)}`;
  }

  if (selectedCustomer) {
    url += `&f-customer=${encodeURIComponent(`${selectedCustomer}`)}`;
  }

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
