import { InvoiceFinanceCalculateType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    calculateInvoiceFinance,
} from '../thunk/invoiceThunk';

interface InvoiceFinanceState {
    finance: InvoiceFinanceCalculateType
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    fieldErrors?: Record<string, string[]>;
}

// ✅ Define the custom broadcast action
// export const broadcastInvoicesUpdated = createAction<AllInvoiceType[]>(
//   'BROADCAST_INVENTORIES_UPDATED',
// );

const initialState: InvoiceFinanceState = {
    finance: {
        sub_total: 0,
        discount_percentage: 0,
        discount_amount: 0,
        tax_amount: 0,
        tax_percentage: 0,
        tax_name: "",
        total: 0,
        cost: 0,
        margin_percentage: 0,
        margin_amount: 0,
        deposit_percentage: 0,
        deposit_amount: 0,
        total_net_payments: 0,
        invoice_balance: 0
    },
    status: 'idle',
    error: null,
    fieldErrors: {},
};

const invoiceFinanceSlice = createSlice({
    name: 'invoice-finance',
    initialState,
    reducers: {
        clearFinance(state) {
            state.finance = initialState.finance;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(calculateInvoiceFinance.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(
                calculateInvoiceFinance.fulfilled,
                (
                    state,
                    action: PayloadAction<{
                        data: InvoiceFinanceCalculateType;
                    }>,
                ) => {
                    state.status = 'succeeded';
                    state.finance = action.payload.data;
                },
            )
            .addCase(calculateInvoiceFinance.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    },
});

export const { clearFinance } = invoiceFinanceSlice.actions;
export default invoiceFinanceSlice.reducer;
