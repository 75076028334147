import { InvoiceType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSingleInvoice } from '../thunk/invoiceThunk';

interface InvoiceSingleState {
  invoice: InvoiceType;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: InvoiceSingleState = {
  invoice: {
    created_at: '',
    uuid: '',
    company_id: '',
    invoice_number: '',
    title: '',
    issued_date: '',
    payment_due: '',
    customer_message: '',
    tax_rate: 0,
    tax_rate_id: '',
    tax_name: '',
    tax_percentage: '',
    total_net_payments: 0,
    subtotal: '',
    discount: 0,
    discount_percentage: 0,
    tax_amount: '',
    total_price: 0,
    cost: '',
    margin_value: '',
    margin_percentage: '',
    invoice_balance: '',
    total_product_cost: 0,
    total_labour_cost: 0,
    total_expenses: 0,
    total_profit: 0,
    margin: 0,
    status: 0,
    notes: [],
    job: {
      id: '',
      title: '',
      job_number: '',
    },
    customer: {
      uuid: '',
      name: '',
      email: '',
      phone: '',
    },
    customer_site: {
      uuid: '',
      name: '',
      address: {
        uuid: '',
        street_number: '',
        street_name: '',
        suburb: '',
        city: '',
        region: '',
        country: '',
        postal_code: '',
        longitude: '',
        latitude: '',
        search_keyword: ''
      },
      address_full: '',
    },
    invoice_payments: [],
    invoice_products: [],
    invoice_expenses: [],
    invoice_timesheets: [],
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const invoiceSingleSlice = createSlice({
  name: 'invoice-single-state',
  initialState,
  reducers: {
    clearInvoice(state) {
      state.invoice = initialState.invoice;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleInvoice.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchSingleInvoice.fulfilled,
        (state, action: PayloadAction<InvoiceType>) => {
          state.status = 'succeeded';
          state.invoice = action.payload;
        },
      )
      .addCase(fetchSingleInvoice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearInvoice } = invoiceSingleSlice.actions;
export default invoiceSingleSlice.reducer;
