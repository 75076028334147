import { CustomerNoteType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCustomerSingleNote } from '../thunk/customerNoteThunk';

interface CustomerSingleNoteState {
  customerNote: CustomerNoteType;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: CustomerSingleNoteState = {
  customerNote: {
    uuid: '',
    note: '',
    attachment: {
      link: '',
      size: '',
      name: '',
    },
    created_at: '',
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const customerSingleNoteSlice = createSlice({
  name: 'customer-single-note-state',
  initialState,
  reducers: {
    clearCustomerNote(state) {
      state.customerNote = initialState.customerNote;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerSingleNote.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchCustomerSingleNote.fulfilled,
        (state, action: PayloadAction<CustomerNoteType>) => {
          state.status = 'succeeded';
          state.customerNote = action.payload;
        },
      )
      .addCase(fetchCustomerSingleNote.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearCustomerNote } = customerSingleNoteSlice.actions;
export default customerSingleNoteSlice.reducer;
