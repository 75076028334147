import { API_METHOD_POST, API_URL } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const CalculateInvoiceFinance = async (
    data: any,
) => {
    const method: Method = API_METHOD_POST;
    const url = `${API_URL}/common/calculate-finances`;

    const jsonString = JSON.stringify(data);
    const additionalHeaders = { hash: generateHash(jsonString) };

    try {
        const response = await apiCall({
            method,
            url,
            data,
            headers: additionalHeaders,
        });
        return response;
    } catch (error) {
        throw error;
    }
};
