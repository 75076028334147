import { createSlice } from '@reduxjs/toolkit';

const supplierErrorSlice = createSlice({
    name: 'supplierError',
    initialState: {
        error: null,
    },
    reducers: {
        setErrors(state, action) {
            state.error = action.payload;
        },
        clearError(state) {
            state.error = null;
        },
    },
});

export const { setErrors, clearError } = supplierErrorSlice.actions;
export default supplierErrorSlice.reducer;
