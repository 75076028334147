import { InvoiceNoteType, NewEnquiryNoteType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchEnquirySingleNote } from '../thunk/enquiryNoteThunk';

interface EnquirySingleNoteState {
  enquiryNote: InvoiceNoteType;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: EnquirySingleNoteState = {
  enquiryNote: {
    uuid: '',
    note: '',
    attachment: {
      link: '',
      size: '',
      name: '',
    },
    created_at: '',
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const enquirySingleNoteSlice = createSlice({
  name: 'enquiry-single-note-state',
  initialState,
  reducers: {
    clearEnquiryNote(state) {
      state.enquiryNote = initialState.enquiryNote;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEnquirySingleNote.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchEnquirySingleNote.fulfilled,
        (state, action: PayloadAction<NewEnquiryNoteType>) => {
          state.status = 'succeeded';
          state.enquiryNote = action.payload;
        },
      )
      .addCase(fetchEnquirySingleNote.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearEnquiryNote } = enquirySingleNoteSlice.actions;
export default enquirySingleNoteSlice.reducer;
