import { API_METHOD_PUT, API_VARIATIONS } from '@/constants/apiConstants';
import { ScheduleStatusUpdateFormData } from '@/redux/thunk/tradeWorkflowThunk';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const ChangeScheduleStatus = async (
    updatedStatusData: ScheduleStatusUpdateFormData,
) => {
    const method: Method = API_METHOD_PUT;
    const url = API_VARIATIONS + `/${updatedStatusData.jobUuid}/service-schedules/${updatedStatusData.serviceUuid}/update-status`;
    const data = {
        status: updatedStatusData.status,
    };

    const jsonString = JSON.stringify(data);
    const additionalHeaders = { hash: generateHash(jsonString) };

    try {
        const response = await apiCall({
            method,
            url,
            data,
            headers: additionalHeaders,
        });
        return response;
    } catch (error) {
        throw error;
    }
};
