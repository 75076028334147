import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { Pagination } from '@/helpers/types';
import {
  Inventory,
  InventoryAdjustmentDetailReasons,
  InventoryList,
  MainInventory,
  TransferInventoryProducts,
} from '@/helpers/types/inventoryTypes';
import { fetchInventryData } from '@/api/inventory/fetchInventries';
import { SearchInventries } from '@/api/inventory/searchInventries';
import { fetchMainInventryData } from '@/api/inventory/fetchMainInventries';
import { SearchMainInventries } from '@/api/inventory/searchMainInventries';
import { fetchVanInventryData } from '@/api/inventory/fetchVanInventries';
import { SearchVanInventries } from '@/api/inventory/searchVanInventries';
import { fetchAllInventryData } from '@/api/inventory/fetchAllInventries';
import { fetchInventryItemsData } from '@/api/inventory/fetchInventryItems';
import { TransferInventries } from '@/api/inventory/TransferInventries';
import { DeleteInventoryData } from '@/api/inventory/deleteInventory';
import { fetchInventoryAdjustmentDetailReasonsData } from '@/api/inventory/fetchInventoryAdjustmentDetailReasons';
import { UpdateManualStockData } from '@/api/inventory/updateManualStock';
import { toast } from 'react-toastify';

// Define the type for the thunk's return value
type FetchInventoryResponse = {
  data: Inventory[];
  pagination: Pagination;
};

type FetchAllInventoryResponse = {
  data: InventoryList[];
};

type FetchInventoryItemsResponse = {
  data: MainInventory[];
};

type FetchMainInventoryResponse = {
  data: MainInventory[];
  pagination: Pagination;
};

type FetchVanInventoryResponse = {
  data: MainInventory[];
  pagination: Pagination;
  team_member_full_name: string;
}

type fetchInventoryAdjustmentDetailReasonsResponse = {
  data: InventoryAdjustmentDetailReasons[];
};

type updateManualStockResponse = {
  data: MainInventory;
  message: string;
};

type SearchInventorysArgs = {
  searchTerm: string;
  pageSize: string;
  pagination: number;
  sortBy?: string | null;
  sortOrder?: string | null;
};

type FetchInventoryItemsArgs = {
  uuid: string;
};

type SearchVanInventorysArgs = {
  searchTerm: string;
  uuid: string;
  pageSize: string;
  pagination: number;
};

type FetchQuotesError = string | SerializedError;

type FetchVanInventoryArgs = string;

type TransferInventoryResponse = {
  data: {};
  message: string;
};

type TransferInventoryArgs = {
  from_inventory_id: string;
  to_inventory_id: string;
  reason: number;
  status: number;
  products: TransferInventoryProducts[];
};

type DeleteInventoryArgs = {
  uuid: string;
};

type updateManualStockArgs = {
  quantity: number;
  reason: number;
  note: string;
  uuid: string;
};

export const fetchInventoryData = createAsyncThunk<
  FetchInventoryResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: FetchQuotesError; // Type for rejected value
  }
>('inventory/fetchInventoryData', async (_, { rejectWithValue }) => {
  try {
    const response = await fetchInventryData();
    const data: FetchInventoryResponse = response?.data;
    return { data: data.data, pagination: data.pagination }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const searchInventoryData = createAsyncThunk<
  FetchInventoryResponse,
  SearchInventorysArgs,
  {
    rejectValue: FetchQuotesError;
  }
>(
  'inventory/searchInventoryData',
  async (
    { searchTerm, pageSize, pagination, sortBy, sortOrder },
    { rejectWithValue },
  ) => {
    try {
      const response = await SearchInventries(
        searchTerm,
        pageSize,
        pagination,
        sortBy,
        sortOrder,
      );
      const data: FetchInventoryResponse = response.data;
      return { data: data.data, pagination: data.pagination };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const fetchMainInventoryData = createAsyncThunk<
  FetchMainInventoryResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: FetchQuotesError; // Type for rejected value
  }
>('inventory/fetchMainInventoryData', async (_, { rejectWithValue }) => {
  try {
    const response = await fetchMainInventryData();
    const data: FetchMainInventoryResponse = response?.data;
    return { data: data.data, pagination: data.pagination }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const searchMainInventoryData = createAsyncThunk<
  FetchMainInventoryResponse,
  SearchInventorysArgs,
  {
    rejectValue: FetchQuotesError;
  }
>(
  'inventory/searchMainInventoryData',
  async ({ searchTerm, pageSize, pagination }, { rejectWithValue }) => {
    try {
      const response = await SearchMainInventries(
        searchTerm,
        pageSize,
        pagination,
      );
      const data: FetchMainInventoryResponse = response.data;
      return { data: data.data, pagination: data.pagination };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const fetchVanInventoryData = createAsyncThunk<
  FetchVanInventoryResponse, // Return type of the payload creator
  FetchVanInventoryArgs, // Argument type of the payload creator
  {
    rejectValue: FetchQuotesError; // Type for rejected value
  }
>(
  'inventory/fetchVanInventoryData',
  async (uuid: string, { rejectWithValue }) => {
    try {
      const response = await fetchVanInventryData(uuid);
      const data: FetchMainInventoryResponse = response?.data;
      // return { data: data.data, pagination: data.pagination }; // Return formatted data
      return { data: data.data, pagination: data.pagination, team_member_full_name: response.team_member_full_name }; // Return formatted data
    } catch (error) {
      // Type guard to narrow down error to Error type
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      // Fallback for unknown error types
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const searchVanInventoryData = createAsyncThunk<
  FetchMainInventoryResponse,
  SearchVanInventorysArgs,
  {
    rejectValue: FetchQuotesError;
  }
>(
  'inventory/searchVanInventoryData',
  async ({ searchTerm, uuid, pageSize, pagination }, { rejectWithValue }) => {
    try {
      const response = await SearchVanInventries(
        searchTerm,
        uuid,
        pageSize,
        pagination,
      );
      const data: FetchMainInventoryResponse = response.data;
      return { data: data.data, pagination: data.pagination };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const fetchAllInventoryData = createAsyncThunk<
  FetchAllInventoryResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: FetchQuotesError; // Type for rejected value
  }
>('inventory/fetchAllInventoryData', async (_, { rejectWithValue }) => {
  try {
    const response = await fetchAllInventryData();
    const data: FetchAllInventoryResponse = response;
    return { data: data.data }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const fetchInventoryItems = createAsyncThunk<
  FetchInventoryItemsResponse, // Return type of the payload creator
  FetchInventoryItemsArgs, // Argument type of the payload creator
  {
    rejectValue: FetchQuotesError; // Type for rejected value
  }
>('inventory/fetchInventoryItems', async ({ uuid }, { rejectWithValue }) => {
  try {
    const response = await fetchInventryItemsData(uuid);
    const data: FetchInventoryItemsResponse = response;
    return { data: data.data }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const transferInventories = createAsyncThunk<
  TransferInventoryResponse, // Return type of the payload creator
  TransferInventoryArgs, // Argument type of the payload creator
  {
    rejectValue: FetchQuotesError; // Type for rejected value
  }
>(
  'inventory/transferInventries',
  async (
    { from_inventory_id, to_inventory_id, reason, status, products },
    { rejectWithValue },
  ) => {
    try {
      const response = await TransferInventries(
        from_inventory_id,
        to_inventory_id,
        reason,
        status,
        products,
      );
      toast.success(response.message);
      const data: TransferInventoryResponse = response;
      return { data: data.data, message: data.message }; // Return formatted data
    } catch (error) {
      // Type guard to narrow down error to Error type
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      // Fallback for unknown error types
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const deleteInventory = createAsyncThunk<
  void, // Return type of the payload creator
  DeleteInventoryArgs, // Argument type of the payload creator
  {
    rejectValue: FetchQuotesError; // Type for rejected value
  }
>(
  'inventory/deleteInventory',
  async ({ uuid }, { dispatch, rejectWithValue }) => {
    try {
      // Call the API to delete the quote
      await DeleteInventoryData(uuid);
      toast.success('Inventory item deleted successfully');
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const fetchInventoryAdjustmentDetailReasons = createAsyncThunk<
  fetchInventoryAdjustmentDetailReasonsResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: FetchQuotesError; // Type for rejected value
  }
>(
  'inventory/fetchInventoryAdjustmentDetailReasons',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchInventoryAdjustmentDetailReasonsData();
      const data: fetchInventoryAdjustmentDetailReasonsResponse = response;
      return { data: data.data }; // Return formatted data
    } catch (error) {
      // Type guard to narrow down error to Error type
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      // Fallback for unknown error types
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const UpdateManualStock = createAsyncThunk<
  updateManualStockResponse, // Return type of the payload creator
  updateManualStockArgs, // Argument type of the payload creator
  {
    rejectValue: FetchQuotesError; // Type for rejected value
  }
>(
  'inventory/updateManualStock',
  async ({ quantity, reason, note, uuid }, { rejectWithValue }) => {
    try {
      const response = await UpdateManualStockData(
        quantity,
        reason,
        note,
        uuid,
      );
      toast.success(response.message);
      const data: updateManualStockResponse = response;
      return data; // Return formatted data
    } catch (error) {
      // Type guard to narrow down error to Error type
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      // Fallback for unknown error types
      return rejectWithValue('An unknown error occurred');
    }
  },
);
