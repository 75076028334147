import { SingleVariationType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSingleVariation } from '../thunk/tradeWorkflowThunk';

interface VariationSingleState {
    variation: SingleVariationType;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    fieldErrors?: Record<string, string[]>;
}

const initialState: VariationSingleState = {
    variation: {
        uuid: '',
        created_at: '',
        signature: {
            link: '',
            size: '',
            name: ''
        },
        description: '',
        attachments: [],
    },
    status: 'idle',
    error: null,
    fieldErrors: {},
}

const variationSingleSlice = createSlice({
    name: 'variation-single-state',
    initialState,
    reducers: {
        clearVariation(state) {
            state.variation = initialState.variation;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSingleVariation.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(
                fetchSingleVariation.fulfilled,
                (state, action: PayloadAction<SingleVariationType>) => {
                    state.status = 'succeeded';
                    state.variation = action.payload;
                },
            )
            .addCase(fetchSingleVariation.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    },
});

export const { clearVariation } = variationSingleSlice.actions;
export default variationSingleSlice.reducer;
