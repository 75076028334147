import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import {
  API_INVOICE_SYNC_XERO,
  API_METHOD_POST,
} from '@/constants/apiConstants';

export const SyncWithXero = async (invoiceUuid: string) => {
  const method: Method = API_METHOD_POST;
  const url = API_INVOICE_SYNC_XERO + `/${invoiceUuid}`;

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
