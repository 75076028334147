import { ScheduleType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAllSchedules } from '../thunk/tradeWorkflowThunk';

interface SchedulesAllState {
    schedules: ScheduleType[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    fieldErrors?: Record<string, string[]>;
}

const initialState: SchedulesAllState = {
    schedules: [],
    status: 'idle',
    error: null,
    fieldErrors: {},
};

const schedulesAllSlice = createSlice({
    name: 'schedules-all',
    initialState,
    reducers: {
        clearSchedules(state) {
            state.schedules = initialState.schedules;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllSchedules.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(
                fetchAllSchedules.fulfilled,
                (
                    state,
                    action: PayloadAction<{
                        data: ScheduleType[];
                    }>,
                ) => {
                    state.status = 'succeeded';
                    state.schedules = action.payload.data;
                },
            )
            .addCase(fetchAllSchedules.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    },
});

export const { clearSchedules } = schedulesAllSlice.actions;
export default schedulesAllSlice.reducer;
