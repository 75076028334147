import { NewEnquiryNoteType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchEnquiryAllNotes } from '../thunk/enquiryNoteThunk';

interface EnquiryAllNoteState {
  enquiryNotes: NewEnquiryNoteType[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: EnquiryAllNoteState = {
  enquiryNotes: [],
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const enquiryAllNoteSlice = createSlice({
  name: 'enquiry-all-note',
  initialState,
  reducers: {
    clearEnquiryNotes(state) {
      state.enquiryNotes = initialState.enquiryNotes;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEnquiryAllNotes.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchEnquiryAllNotes.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: NewEnquiryNoteType[];
          }>,
        ) => {
          state.status = 'succeeded';
          state.enquiryNotes = action.payload.data;
        },
      )
      .addCase(fetchEnquiryAllNotes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearEnquiryNotes } = enquiryAllNoteSlice.actions;
export default enquiryAllNoteSlice.reducer;
