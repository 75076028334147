/* eslint-disable prettier/prettier */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CustomerPortalSingleInvoice,
} from "@/helpers/types/customerPortalTypes";
import { fetchInvoiceByUuid } from "@/redux/thunk/customer-portal/invoiceThunk";

interface SingleInvoice {
  selectedInvoiceData: CustomerPortalSingleInvoice;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: SingleInvoice = {
  selectedInvoiceData: {
    uuid: '',
    company_id: '',
    job: {
      id: '',
      title: '',
      job_number: '',
    },
    invoice_number: '',
    title:'',
    issued_date: '',
    payment_due: '',
    customer_message: '',
    tax_rate: '',
    tax_rate_id: '',
    subtotal: '',
    discount: '',
    tax_amount: '',
    total_price: '',
    cost: '',
    margin_value: '',
    margin_percentage: '',
    invoice_balance: '',
    total_product_cost: '',
    total_labour_cost: '',
    total_expenses: '',
    total_profit: '',
    margin: '',
    status: '',
    customer: {
      uuid: '',
      name: '',
      email: '',
      phone: '',
    },
    customer_site:{
      uuid:'',
      name: '',
      address: {
        uuid: '',
        street_number: '',
        street_name: '',
        suburb: '',
        city: '',
        region: '',
        country: '',
        postal_code: '',
        longitude: '',
        latitude: '',
        search_keyword: '',
      },
      address_full: '',
    },
    invoice_products: [
      {
        uuid: '',
        invoice: {
          id: '',
          title: '',
          invoice_number: '',
        },
        product: {
          id: '',
          name: '',
          item_type: '',
        },
        invoice_item_type: '',
        entered_date: '',
        name: '',
        description: '',
        attachment_url: {
          link: '',
          size: '',
          name: '',
        },
        unit_cost: '',
        unit_price: '',
        quantity: '',
        total: '',
      },
    ],
    invoice_expenses: [
      {
        uuid: '',
        job: {
          id: '',
          name: '',
        },
        date: '',
        name: '',
        details: '',
        amount: '',
        attachment_url: {
          link: '',
          size: '',
          name: '',
        },
      },
    ],
    invoice_payments: [
      {
        uuid: '',
        invoice_id: 0,
        date: '',
        method: 0,
        amount: 0,
        pdf_url: '',
        reference: '',
        details: '',
      }
    ],
  },
  status: 'idle',
  error: null,
};

const singleInvoiceSlice = createSlice({
  name: 'single-invoice',
  initialState,
  reducers: {
    clearSingleInvoice(state) {
      state.selectedInvoiceData = initialState.selectedInvoiceData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoiceByUuid.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchInvoiceByUuid.fulfilled,
        (state, action: PayloadAction<{ data: CustomerPortalSingleInvoice }>) => {
          state.selectedInvoiceData = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchInvoiceByUuid.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearSingleInvoice } = singleInvoiceSlice.actions;
export default singleInvoiceSlice.reducer;
