import { AllProductType, Pagination } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchProducts, searchProduct } from '../thunk/productThunk';

interface ProductAllState {
  product: AllProductType[];
  pagination: Pagination;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: ProductAllState = {
  product: [],
  pagination: {
    current_page: 0,
    current_records: 0,
    record_from: 0,
    record_to: 0,
    total_pages: 0,
    total_records: 0,
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const productAllSlice = createSlice({
  name: 'product-all',
  initialState,
  reducers: {
    clearProducts(state) {
      state.product = initialState.product;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchProducts.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: AllProductType[];
            pagination: Pagination;
          }>,
        ) => {
          state.status = 'succeeded';
          state.product = action.payload.data;
          state.pagination = action.payload.pagination;
        },
      )
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    // Search product
    builder
      .addCase(searchProduct.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        searchProduct.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: AllProductType[];
            pagination: Pagination;
          }>,
        ) => {
          state.status = 'succeeded';
          state.product = action.payload.data;
          state.pagination = action.payload.pagination;
        },
      )
      .addCase(searchProduct.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })

      // Handle cross-tab syncing
      .addCase(
        'BROADCAST_PRODUCTS_UPDATED',
        (state, action: PayloadAction<AllProductType[]>) => {
          state.product = action.payload;
        },
      );
  },
});

export const { clearProducts } = productAllSlice.actions;
export default productAllSlice.reducer;
