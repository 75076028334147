import { ChangeScheduleStatus } from '@/api/tradeWorkflow/changeScheduleStatus';
import { ChangeVariationStatus } from '@/api/tradeWorkflow/changeVariationStatus';
import { GetAllInventories } from '@/api/tradeWorkflow/getAllInventories';
import { GetAllPurchaseOrders } from '@/api/tradeWorkflow/getAllPurchaseOrders';
import { GetAllSchedules } from '@/api/tradeWorkflow/getAllSchedules';
import { GetAllVariations } from '@/api/tradeWorkflow/getAllVariations';
import { GetSingleSchedule } from '@/api/tradeWorkflow/getSingleSchedule';
import { GetSingleVariation } from '@/api/tradeWorkflow/getSingleVariation';
import {
    AllInventoriesType,
    AllPurchaseOrdersType,
    AllVariationsType,
    ScheduleType,
    SingleVariationType,
} from '@/helpers/types';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';

// Types
// Response types
type FetchAllVariationsResponse = {
    data: AllVariationsType[];
};

type UpdateStatusResponse = {
    status: string
    message: string
}

type FetchAllPurchaseOrdersResponse = {
    data: AllPurchaseOrdersType[]
}

type FetchAllInventoriesResponse = {
    data: AllInventoriesType[]
}

type FetchAllSchedulesResponse = {
    data: ScheduleType[]
}

type UpdateScheduleStatusResponse = {
    status: string
    message: string
}

// Argument types
type FetchAllVariationsArgs = {
    jobUuid: string;
};

type FetchSingleVariationArgs = {
    jobUuid: string
    onSiteUuid: string
}

export interface VariationStatusUpdateFormData {
    jobUuid: string
    onSiteUuid: string
    status: number
}

type FetchAllPurchaseOrdersArgs = {
    jobUuid: string
}

type FetchAllInventoriesArgs = {
    jobUuid: string
}

type FetchAllSchedulesArgs = {
    jobUuid: string
}

type FetchSingleScheduleArgs = {
    jobUuid: string
    serviceUuid: string
}

export interface ScheduleStatusUpdateFormData {
    jobUuid: string
    serviceUuid: string
    status: number
}

type FetchVariationError = string | SerializedError;
type FetchPurchaseOrdersError = string | SerializedError;
type FetchSchedulesError = string | SerializedError;

// Fetch all variations
export const fetchAllVariations = createAsyncThunk<
    FetchAllVariationsResponse,
    FetchAllVariationsArgs,
    { rejectValue: FetchVariationError }
>('trade/fetchAllVariations', async ({ jobUuid }, { rejectWithValue }) => {
    try {
        const response = await GetAllVariations(jobUuid);
        const data: FetchAllVariationsResponse = response;
        return { data: data.data };
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
    }
    return rejectWithValue('An unknown error occurred');
});

// Fetch single variation
export const fetchSingleVariation = createAsyncThunk<
    SingleVariationType,
    FetchSingleVariationArgs,
    { rejectValue: FetchVariationError }
>(
    'trade/fetchSingleVariation',
    async ({ jobUuid, onSiteUuid }, { rejectWithValue }) => {
        try {
            const response = await GetSingleVariation(jobUuid, onSiteUuid);
            return response.data;
        } catch (error: any) {
            if (error instanceof Error) {
                return rejectWithValue({ message: error.message });
            }
            // if (error.response) {
            //   const { message, messages } = error.response.data;
            //   return rejectWithValue({ message, messages });
            // }
        }
        return rejectWithValue({ message: 'An unknown error occurred' });
    },
);

// Update variation status
export const updateVariationStatus = createAsyncThunk<
    UpdateStatusResponse,
    VariationStatusUpdateFormData,
    { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
    'trade/updateVariationStatus',
    async (updatedStatusData: VariationStatusUpdateFormData, { rejectWithValue }) => {
        try {
            const response = await ChangeVariationStatus(updatedStatusData);
            return response;
        } catch (error: any) {
            if (error.response) {
                const { message, messages } = error.response.data;
                return rejectWithValue({ message, messages });
            }
            if (error instanceof Error) {
                return rejectWithValue({ message: error.message });
            }
        }
        return rejectWithValue({ message: 'An unknown error occurred' });
    },
);

// Fetch all purchase orders
export const fetchAllPurchaseOrders = createAsyncThunk<
    FetchAllPurchaseOrdersResponse,
    FetchAllPurchaseOrdersArgs,
    { rejectValue: FetchPurchaseOrdersError }
>('trade/fetchAllPurchaseOrders', async ({ jobUuid }, { rejectWithValue }) => {
    try {
        const response = await GetAllPurchaseOrders(jobUuid);
        const data: FetchAllPurchaseOrdersResponse = response;
        return { data: data.data };
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
    }
    return rejectWithValue('An unknown error occurred');
});

// Fetch all inventories
export const fetchAllInventories = createAsyncThunk<
    FetchAllInventoriesResponse,
    FetchAllInventoriesArgs,
    { rejectValue: FetchVariationError }
>('trade/fetchAllVanStocks', async ({ jobUuid }, { rejectWithValue }) => {
    try {
        const response = await GetAllInventories(jobUuid);
        const data: FetchAllInventoriesResponse = response;
        return { data: data.data };
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
    }
    return rejectWithValue('An unknown error occurred');
});

// Fetch all schedules
export const fetchAllSchedules = createAsyncThunk<
    FetchAllSchedulesResponse,
    FetchAllSchedulesArgs,
    { rejectValue: FetchSchedulesError }
>('trade/fetchAllSchedules', async ({ jobUuid }, { rejectWithValue }) => {
    try {
        const response = await GetAllSchedules(jobUuid);
        const data: FetchAllSchedulesResponse = response;
        return { data: data.data };
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
    }
    return rejectWithValue('An unknown error occurred');
});

// Fetch single schedule
export const fetchSingleSchedule = createAsyncThunk<
    ScheduleType,
    FetchSingleScheduleArgs,
    { rejectValue: FetchVariationError }
>(
    'trade/fetchSingleSchedule',
    async ({ jobUuid, serviceUuid }, { rejectWithValue }) => {
        try {
            const response = await GetSingleSchedule(jobUuid, serviceUuid);
            return response.data;
        } catch (error: any) {
            if (error instanceof Error) {
                return rejectWithValue({ message: error.message });
            }
            // if (error.response) {
            //   const { message, messages } = error.response.data;
            //   return rejectWithValue({ message, messages });
            // }
        }
        return rejectWithValue({ message: 'An unknown error occurred' });
    },
);

// Update schedule status
export const updateScheduleStatus = createAsyncThunk<
    UpdateScheduleStatusResponse,
    ScheduleStatusUpdateFormData,
    { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
    'trade/updateScheduleStatus',
    async (updatedStatusData: ScheduleStatusUpdateFormData, { dispatch, rejectWithValue }) => {
        try {
            const response = await ChangeScheduleStatus(updatedStatusData);
            await dispatch(fetchAllSchedules({ jobUuid: updatedStatusData.jobUuid }))
            return response;
        } catch (error: any) {
            if (error.response) {
                const { message, messages } = error.response.data;
                return rejectWithValue({ message, messages });
            }
            if (error instanceof Error) {
                return rejectWithValue({ message: error.message });
            }
        }
        return rejectWithValue({ message: 'An unknown error occurred' });
    },
);
