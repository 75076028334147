import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Company, CompanyDetails, Subscription, User } from '@/helpers/types';
import { fetchSubscription } from '@/redux/thunk/subscriptionCheckThunk';
import { loginResponse } from '@/helpers/types/authTypes';
import { fetchLogin, fetchLogout, fetchUser } from '@/redux/thunk/authThunk';
import { fetchCompanyDetails } from '@/redux/thunk/companyDetailsUpdateThunk';

interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  access_token: {
    expires_at: string;
    token: string;
  } | null;
  refresh_token: {
    expires_at: string;
    token: string;
  } | null;
  subscription: Subscription;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  company: {
    uuid: string;
    name: string;
    date_format: {
      id: string;
      frontend_format: string;
      name: string;
    };
    first_day_of_week: {
      id: number;
      name: string;
    };
    time_format: {
      id: string;
      name: string;
      frontend_format: string;
    };
    timezone: {
      id: string;
      name: string;
      offset: string;
      timezone: string;
    };
    payment_integration: {
      stripe: boolean;
    };
  };
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  access_token: null,
  refresh_token: null,
  subscription: {
    users: 0,
    is_trial: false,
    expiry: '',
    active_users: 0,
    available_months: 0,
    is_expired: false,
    current_subscription_package_id: '',
    subscription_packages: null,
    message: {},
  },
  company: {
    uuid: '',
    name: '',
    date_format: {
      id: '',
      frontend_format: '',
      name: '',
    },
    first_day_of_week: {
      id: '',
      name: '',
    },
    time_format: {
      id: '',
      name: '',
      frontend_format: '',
    },
    timezone: {
      id: '',
      name: '',
      offset: '',
      timezone: '',
    },
    payment_integration: {
      stripe: false,
    },
  },
  status: 'idle',
  error: null,
};

// Define a custom action type for HYDRATE
interface HydrateAction {
  type: 'HYDRATE';
  payload: AuthState;
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<loginResponse>) => {
      state.isAuthenticated = true;
      state.user = action.payload?.user;
      state.refresh_token = action.payload?.refresh_token;
      state.access_token = action.payload?.access_token;
      state.subscription = action.payload?.subscription;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.refresh_token = null;
      state.access_token = null;
      state.subscription = initialState.subscription;
    },
  },
  extraReducers: (builder) => {
    builder
      // HYDRATE action for syncing state
      .addCase('HYDRATE', (state, action: HydrateAction) => {
        // Merge the new state with the current state
        return {
          ...state,
          ...action.payload,
        };
      })
      .addCase(fetchLogin.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchLogin.fulfilled,
        (state, action: PayloadAction<loginResponse>) => {
          state.isAuthenticated = true;
          state.user = action.payload?.user;
          state.refresh_token = action.payload?.refresh_token;
          state.access_token = action.payload?.access_token;
          state.subscription = action.payload?.subscription;
          state.company = action.payload?.company;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchLogin.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as unknown as string;
        state.isAuthenticated = false;
        state.user = null;
        state.refresh_token = null;
        state.access_token = null;
        state.subscription = initialState.subscription;
      });

    builder
      .addCase(fetchLogout.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchLogout.fulfilled,
        (state, action: PayloadAction<{ data: [] }>) => {
          state.isAuthenticated = false;
          state.user = null;
          state.refresh_token = null;
          state.access_token = null;
          state.subscription = initialState.subscription;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchLogout.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as unknown as string;
      });

    builder.addCase(
      fetchSubscription.fulfilled,
      (state, action: PayloadAction<{ data: Subscription }>) => {
        state.subscription = action.payload.data;
      },
    );

    builder.addCase(
      fetchUser.fulfilled,
      (state, action: PayloadAction<{ data: User }>) => {
        state.user = action.payload.data;
      },
    );

    builder.addCase(
      fetchCompanyDetails.fulfilled,
      (state, action: PayloadAction<{ data: CompanyDetails }>) => {
        state.company = action.payload.data;
      },
    );
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
