/* eslint-disable prettier/prettier */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CustomerPortalSingleQuote,
} from "@/helpers/types/customerPortalTypes";
import { fetchQuoteByUuid } from "@/redux/thunk/customer-portal/quoteThunk";

interface SingleQuote {
  selectedQuoteData: CustomerPortalSingleQuote;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: SingleQuote = {
  selectedQuoteData: {
    uuid: '',
    company_id: '',
    customer: {
      id: '',
      name: '',
      address: {
        uuid: '',
        street_number: '',
        street_name: '',
        suburb: '',
        city: '',
        region: '',
        country: '',
        postal_code: '',
        longitude: '',
        latitude: '',
        search_keyword: '',
      },
      address_full: '',
      email: '',
      phone:'',
    },
    email_quote_to_customer: false,
    quote_number: '',
    rating: '',
    title: '',
    client_message: '',
    disclaimer: '',
    tax_rate: '',
    tax_rate_id: '',
    subtotal: '',
    discount: '',
    tax_amount: '',
    total: '',
    margin_value: '',
    margin_percentage: '',
    cost: '',
    deposit_required: '',
    created: '',
    status: '',
    products: [
      {
        product_id: '',
        uuid: '',
        item_type: '',
        name: '',
        description: '',
        attachment_url: {
          link: '',
          size: '',
          name: '',
        },
        unit_cost: '',
        unit_price: '',
        quantity:'',
        total: '',
      },
    ],
    labours: [
      {
        uuid: '',
        start: '',
        end: '',
        hours: '',
        cost: '',
        note: '',
      },
    ],
    expenses:[
      {
        uuid:  '',
        item:  '',
        amount:  '',
      },
    ],
  },
  status: 'idle',
  error: null,
};

const singleQuoteSlice = createSlice({
  name: 'single-quote',
  initialState,
  reducers: {
    clearSingleQuote(state) {
      state.selectedQuoteData = initialState.selectedQuoteData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuoteByUuid.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchQuoteByUuid.fulfilled,
        (state, action: PayloadAction<{ data: CustomerPortalSingleQuote }>) => {
          state.selectedQuoteData = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchQuoteByUuid.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearSingleQuote } = singleQuoteSlice.actions;
export default singleQuoteSlice.reducer;
