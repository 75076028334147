/* eslint-disable prettier/prettier */
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { GetCustomerPortalJobData } from '@/api/customerPortal/getJobs';
import {
  CustomerPortalJob,
  CustomerPortalSingleJob,
  CustomerPortalSingleQuote
} from "@/helpers/types/customerPortalTypes";
import { Pagination } from "@/helpers/types";
import { SearchCustomerPortalJobsData } from "@/api/customerPortal/jobs/searchJobs";
import { FetchJobByUuid } from "@/api/customerPortal/jobs/fetchJobByUuid";

type fetchCustomerPortalJobResponse = {
    data: CustomerPortalJob[];
    pagination: Pagination;
};
type FetchJobByUuidResponse = {
  data: CustomerPortalSingleJob;
};
type FetchJobByUuidArgs = {
  referenceKey: string;
  uuid: string;
};

type fetchCustomerPortalJobArgs = {
    uniqueKey: string;
}

type SearchJobsArgs = {
    referenceKey: string;
    searchTerm: string;
    pageSize: string;
    pagination: number;
    selectedStatus?: string;
    selectedType?: string;
    startDate?: string | null;
    endDate?: string | null;
    selectedCustomer?: string;
};

type FetchJobsError = string | SerializedError;


export const fetchCustomerPortalJobs = createAsyncThunk<
    fetchCustomerPortalJobResponse, // Return type of the payload creator
    fetchCustomerPortalJobArgs, // Argument type of the payload creator
    {
        rejectValue: FetchJobsError; // Type for rejected value
    }
>('customerPortal/fetchCustomerPortalJob', async ({ uniqueKey }, { rejectWithValue }) => {
    try {
        const response = await GetCustomerPortalJobData(uniqueKey);
        const data: fetchCustomerPortalJobResponse = response.data;
        return { data: data.data, pagination: data.pagination }; // Return formatted data
    } catch (error) {
        // Type guard to narrow down error to Error type
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
        // Fallback for unknown error types
        return rejectWithValue('An unknown error occurred');
    }
});

export const searchCustomerPortalJobs = createAsyncThunk<
  fetchCustomerPortalJobResponse,
  SearchJobsArgs, // Updated to use the new argument structure
  {
      rejectValue: FetchJobsError;
  }
>(
  'jobs/searchJobs',
  async (
    {
        referenceKey,
        searchTerm,
        pageSize,
        pagination,
        selectedStatus,
        startDate,
        endDate,
        selectedCustomer,
    },
    { rejectWithValue },
  ) => {
      // Destructure the argument
      try {
          const response = await SearchCustomerPortalJobsData(
            referenceKey,
            searchTerm,
            pageSize,
            pagination,
            selectedStatus,
            startDate,
            endDate,
            selectedCustomer,
          ); // Pass both parameters
          const data: fetchCustomerPortalJobResponse = response.data;
          return { data: data.data, pagination: data.pagination }; // Return formatted data
      } catch (error) {
          if (error instanceof Error) {
              return rejectWithValue(error.message);
          }
          return rejectWithValue('An unknown error occurred');
      }
  },
);

// Fetch Single Job
export const fetchJobByUuid = createAsyncThunk<
  FetchJobByUuidResponse,
  FetchJobByUuidArgs,
  {
    rejectValue: FetchJobsError;
  }
>(
  'customer-portal/jobs/viewJob',
  async (FetchJobByUuidArgs, { rejectWithValue }) => {
    try {
      const response = await FetchJobByUuid(FetchJobByUuidArgs);
      const data: CustomerPortalSingleJob = response?.data;
      return { data: data };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);
