import { API_METHOD_PUT, API_PRODUCTS } from '@/constants/apiConstants';
import { UpdatedProductFormData } from '@/redux/thunk/productThunk';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const UpdateProduct = async (updatedProductData: UpdatedProductFormData) => {
  const method: Method = API_METHOD_PUT;
  const url = API_PRODUCTS + '/' + updatedProductData.productUuid;
  const data = {
    item_type: updatedProductData.item_type,
    name: updatedProductData.name,
    item_code: updatedProductData.item_code,
    description: updatedProductData.description,
    cost: updatedProductData.cost,
    unit_price: updatedProductData.unit_price,
    image_url: updatedProductData.image_url,
    is_tax_exempted: updatedProductData.is_tax_exempted,
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = {
    hash: generateHash(jsonString),
  };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
