import { Pagination } from '@/helpers/types';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import {
  CustomerPortalSingleInvoice,
  InvoicesData,
} from '@/helpers/types/customerPortalTypes';
import { SearchInvoices } from '@/api/customerPortal/invoices/searchInvoices';
import { GetInvoices } from '@/api/customerPortal/invoices/getInvoices';
import { FetchInvoiceByUuid } from '@/api/customerPortal/invoices/fetchInvoiceByUuid';
import { MakeInvoicePayment } from '@/api/customerPortal/invoices/makeInvoicePayment';

type FetchInvoicesResponse = {
  data: InvoicesData[];
  pagination: Pagination;
};

type FetchInvoiceByUuidResponse = {
  data: CustomerPortalSingleInvoice;
};

type MakeInvoicePaymentResponse = {
  status: string;
  data?: {
    url?: string;
  };
};

type FetchInvoiceByUuidArgs = {
  referenceKey: string;
  uuid: string;
};
type MakeInvoicePaymentArgs = {
  referenceKey: string;
  uuid: string;
};

type FetchInvoicesArgs = {
  referenceKey: string;
};
type SearchInvoicesArgs = {
  referenceKey: string;
  searchTerm: string;
  filteredType: number | undefined;
  invoiceCountPerPage: number;
  pageNumber: number;
  filterDateRange: {
    start: string | null;
    end: string | null;
  };
  selectedCustomer?: string;
};

type FetchInvoiceError = string | SerializedError;

// Fetch invoices
export const fetchInvoices = createAsyncThunk<
  FetchInvoicesResponse,
  FetchInvoicesArgs,
  { rejectValue: FetchInvoiceError }
>(
  'customer-portal/invoices/fetchInvoices',
  async ({ referenceKey }, { rejectWithValue }) => {
    try {
      const response = await GetInvoices(referenceKey);
      const data: FetchInvoicesResponse = response.data;
      return { data: data.data, pagination: data.pagination };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
    return rejectWithValue('An unknown error occurred');
  },
);

// Search invoices
export const searchInvoices = createAsyncThunk<
  FetchInvoicesResponse,
  SearchInvoicesArgs,
  { rejectValue: FetchInvoiceError }
>(
  'customer-portal/invoices/searchInvoices',
  async (
    {
      referenceKey,
      searchTerm,
      filteredType,
      invoiceCountPerPage,
      pageNumber,
      filterDateRange,
      selectedCustomer,
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await SearchInvoices(
        referenceKey,
        searchTerm,
        filteredType,
        invoiceCountPerPage,
        pageNumber,
        filterDateRange,
        selectedCustomer,
      );
      const data: FetchInvoicesResponse = response.data;
      return { data: data.data, pagination: data.pagination };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Fetch Single Invoice
export const fetchInvoiceByUuid = createAsyncThunk<
  FetchInvoiceByUuidResponse,
  FetchInvoiceByUuidArgs,
  {
    rejectValue: FetchInvoiceError;
  }
>(
  'customer-portal/invoices/viewInvoice',
  async (FetchInvoiceByUuidArgs, { rejectWithValue }) => {
    try {
      const response = await FetchInvoiceByUuid(FetchInvoiceByUuidArgs);
      const data: CustomerPortalSingleInvoice = response?.data;
      return { data: data };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

// Make Invoice Payment
export const makeInvoicePayment = createAsyncThunk<
  MakeInvoicePaymentResponse,
  MakeInvoicePaymentArgs,
  {
    rejectValue: FetchInvoiceError;
  }
>(
  'customer-portal/invoices/makeInvoicePayment',
  async (MakeInvoicePaymentArgs, { rejectWithValue }) => {
    try {
      const response = await MakeInvoicePayment(MakeInvoicePaymentArgs);
      const data = response?.data;
      return { status: 'SUCCESS', data: data };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);
