/* eslint-disable prettier/prettier */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerPortalSingleRequest } from "@/helpers/types/customerPortalTypes";
import { fetchRequestByUuid } from "@/redux/thunk/customer-portal/requestThunk";

interface SingleRequest {
  selectedRequestData: CustomerPortalSingleRequest;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: SingleRequest = {
  selectedRequestData: {
    uuid: '',
    title: '',
    customer: {
      name: '',
      email: '',
      phone: '',
      uuid: '',
    },
    customer_site: {
      uuid: '',
      name: '',
      address: {
        uuid: '',
        street_number: '',
        street_name: '',
        suburb: '',
        city: '',
        region: '',
        country: '',
        postal_code: '',
        longitude: '',
        latitude: '',
        search_keyword: '',
      },
      address_full: '',
    },
    requested_on: '',
    details: '',
    expected_date: '',
    expected_time: '',
    onsite_assessment_required: '',
    status: '',
    status_label: '',
    onsite_assessment: {
      uuid: '',
      details: '',
      start_date: '',
      end_date: '',
      start_time: '',
      end_time: '',
      send_notification: '',
      members: [],
    },
  },
  status: 'idle',
  error: null,
};

const singleRequestSlice = createSlice({
  name: 'single-request',
  initialState,
  reducers: {
    clearSingleRequest(state) {
      state.selectedRequestData = initialState.selectedRequestData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequestByUuid.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchRequestByUuid.fulfilled,
        (state, action: PayloadAction<{ data: CustomerPortalSingleRequest }>) => {
          state.selectedRequestData = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchRequestByUuid.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearSingleRequest } = singleRequestSlice.actions;
export default singleRequestSlice.reducer;
