import { AllInventoriesType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAllInventories } from '../thunk/tradeWorkflowThunk';

interface InventoriesAllState {
    inventories: AllInventoriesType[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    fieldErrors?: Record<string, string[]>;
}

const initialState: InventoriesAllState = {
    inventories: [],
    status: 'idle',
    error: null,
    fieldErrors: {},
};

const inventoriesAllSlice = createSlice({
    name: 'inventories-all',
    initialState,
    reducers: {
        clearInventories(state) {
            state.inventories = initialState.inventories;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllInventories.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(
                fetchAllInventories.fulfilled,
                (
                    state,
                    action: PayloadAction<{
                        data: AllInventoriesType[];
                    }>,
                ) => {
                    state.status = 'succeeded';
                    state.inventories = action.payload.data;
                },
            )
            .addCase(fetchAllInventories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    },
});

export const { clearInventories } = inventoriesAllSlice.actions;
export default inventoriesAllSlice.reducer;
