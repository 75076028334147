import { AddProduct } from '@/api/products/addProduct';
import { DeleteProduct } from '@/api/products/deleteProduct';
import { ExportProducts } from '@/api/products/exportProducts';
import { GetAllProducts } from '@/api/products/getAllProducts';
import { GetSingleProduct } from '@/api/products/getSingleProduct';
import { ImportProducts } from '@/api/products/importProducts';
import { SearchProduct } from '@/api/products/searchProduct';
import { UpdateProduct } from '@/api/products/updateProduct';
import {
  AllProductType,
  Pagination,
  ProductFormData,
  SingleProductType,
} from '@/helpers/types';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { sendBroadcast } from '@/utility/redux/broadcastChannel';

// Types
// Response types
type FetchAllProductsResponse = {
  data: AllProductType[];
  pagination: Pagination;
};

type AddProductResponse = {
  data: SingleProductType;
  status: string;
};

type UpdateProductResponse = {
  data: SingleProductType;
  status: string;
};

type ExportProductsResponse = {
  status: string;
  message: string;
};

type ImportProductResponse = {
  status: string;
};

type DeleteProductResponse = {
  status: string;
};

// Argument types
type FetchSingleProductArgs = {
  productUuid: string;
};

type SearchProductArgs = {
  filteredType: number | undefined;
  searchTerm: string;
  productCountPerPage: number;
  pageNumber: number;
  sortBy?: string | null;
  sortOrder?: string | null;
};

export interface UpdatedProductFormData extends ProductFormData {
  productUuid: string;
}

type DeleteSingleProductArgs = {
  productUuid: string;
};

export interface ImportProductArgs {
  attachment: string;
}

type FetchProductError = string | SerializedError;

// Fetch all products
export const fetchProducts = createAsyncThunk<
  FetchAllProductsResponse,
  void,
  { rejectValue: FetchProductError }
>('product/fetchAllProducts', async (_, { rejectWithValue }) => {
  try {
    const response = await GetAllProducts();
    const data: FetchAllProductsResponse = response.data;
    return { data: data.data, pagination: data.pagination };
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
  }
  return rejectWithValue('An unknown error occurred');
});

// Fetch single product
export const fetchSingleProduct = createAsyncThunk<
  SingleProductType,
  FetchSingleProductArgs,
  { rejectValue: FetchProductError }
>(
  'product/fetchSingleProduct',
  async ({ productUuid }, { rejectWithValue }) => {
    try {
      const response = await GetSingleProduct(productUuid);
      return response.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      // if (error.response) {
      //   const { message, messages } = error.response.data;
      //   return rejectWithValue({ message, messages });
      // }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Fetch search products
export const searchProduct = createAsyncThunk<
  FetchAllProductsResponse,
  SearchProductArgs,
  { rejectValue: FetchProductError }
>(
  'product/searchProduct',
  async (
    {
      filteredType,
      searchTerm,
      productCountPerPage,
      pageNumber,
      sortBy,
      sortOrder,
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await SearchProduct(
        filteredType,
        searchTerm,
        productCountPerPage,
        pageNumber,
        sortBy,
        sortOrder,
      );
      const data: FetchAllProductsResponse = response.data;
      return { data: data.data, pagination: data.pagination };
    } catch (error: any) {
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Add product
export const addProduct = createAsyncThunk<
  AddProductResponse,
  ProductFormData,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'product/addProduct',
  async (newProductData: ProductFormData, { dispatch, rejectWithValue }) => {
    try {
      const response = await AddProduct(newProductData);
      await dispatch(fetchProducts());

      // Broadcast event
      sendBroadcast({ action: 'PRODUCT_ADDED' });

      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

// Update product
export const updateProduct = createAsyncThunk<
  UpdateProductResponse,
  UpdatedProductFormData,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'product/updateProduct',
  async (
    updatedProductData: UpdatedProductFormData,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await UpdateProduct(updatedProductData);
      await dispatch(fetchProducts());

      // Broadcast event
      sendBroadcast({ action: 'PRODUCT_UPDATED' });

      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Delete single product
export const deleteProduct = createAsyncThunk<
  DeleteProductResponse,
  DeleteSingleProductArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'product/deleteSingleProduct',
  async ({ productUuid }, { dispatch, rejectWithValue }) => {
    try {
      const response = await DeleteProduct(productUuid);
      await dispatch(fetchProducts());

      // ✅ Broadcast event
      sendBroadcast({ action: 'PRODUCT_DELETED' });

      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Import products
export const importProducts = createAsyncThunk<
  ImportProductResponse,
  ImportProductArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'product/importProducts',
  async ({ attachment }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ImportProducts(attachment);
      await dispatch(fetchProducts());
      return response;
    } catch (error: any) {
      if (error.response) {
        return rejectWithValue(error)
        // const { message, messages } = error.response.data;
        // return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Export products
export const exportProducts = createAsyncThunk<
  ExportProductsResponse,
  void,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>('product/exportProducts', async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = await ExportProducts();
    return response;
  } catch (error: any) {
    if (error.response) {
      const { message, messages } = error.response.data;
      return rejectWithValue({ message, messages });
    }
    if (error instanceof Error) {
      return rejectWithValue({ message: error.message });
    }
  }
  return rejectWithValue({ message: 'An unknown error occurred' });
});
