/* eslint-disable prettier/prettier */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CustomerPortalSingleJob,
} from "@/helpers/types/customerPortalTypes";
import { fetchJobByUuid } from "@/redux/thunk/customer-portal/jobThunk";

interface SingleJob {
  selectedJobData: CustomerPortalSingleJob;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: SingleJob = {
  selectedJobData: {
    uuid: '',
    title: '',
    type: '',
    job_number: '',
    customer: {
      id: '',
      name: '',
      address: {
        uuid: '',
        street_number: '',
        street_name: '',
        suburb: '',
        city: '',
        region: '',
        country: '',
        postal_code: '',
        longitude: '',
        latitude: '',
        search_keyword: '',
      },
      address_full: '',
      email: '',
      phone: '',
    },
    related_recurring_job: {
      id: '',
      title: '',
      job_number: '',
    },
    details: '',
    start_date: '',
    end_date: '',
    start_time: '',
    end_time: '',
    recurring_type: '',
    recurring_data: '',
    total_price: '',
    total_product_cost: '',
    total_labour_cost: '',
    total_expenses: '',
    total_profit: '',
    margin: '',
    status: '',
    created_at: '',
    job_products: [
      {
        product_id: '',
        uuid: '',
        job: {
          id: '',
          title: '',
          job_number: '',
        },
        product: {
          id: '',
          name: '',
          item_type: '',
        },
        item_type: '',
        entered_date: '',
        name: '',
        description: '',
        attachment_url: {
          link: '',
          size: '',
          name: '',
        },
        unit_cost: '',
        unit_price: '',
        quantity: '',
        total: '',
      },
    ],
    job_expenses: [
      {
        uuid: '',
        job: {
          id: '',
          name: '',
        },
        date: '',
        name: '',
        details: '',
        amount: '',
        attachment_url: {
          link: '',
          size: '',
          name: '',
        },
      },
    ],
    job_photos: [
      {
        uuid: '',
        photo: {
          link: '',
          size: '',
          name:'',
        },
        type: '',
      },
    ],
  },
  status: 'idle',
  error: null,
};

const singleJobSlice = createSlice({
  name: 'single-job',
  initialState,
  reducers: {
    clearSingleJob(state) {
      state.selectedJobData = initialState.selectedJobData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobByUuid.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchJobByUuid.fulfilled,
        (state, action: PayloadAction<{ data: CustomerPortalSingleJob }>) => {
          state.selectedJobData = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchJobByUuid.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearSingleJob } = singleJobSlice.actions;
export default singleJobSlice.reducer;
