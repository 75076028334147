import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchLastUpdate } from '@/redux/thunk/lastUpdateThunk';

// Define the interface for the response data
interface LastUpdateData {
  user_updated: boolean;
  company_settings_updated: boolean;
}

// Define the interface for the state
interface LastUpdateState {
  data: LastUpdateData | null | undefined;
  loading: boolean;
  error: string | null;
}

// Initial state
const initialState: LastUpdateState = {
  data: {
    user_updated: false,
    company_settings_updated: false,
  },
  loading: false,
  error: null,
};

// Create the slice
const lastUpdateSlice = createSlice({
  name: 'lastUpdate',
  initialState,
  reducers: {
    clearLastUpdate(state) {
      state.data = null; // Clear finance data
      state.loading = false; // Reset loading state
      state.error = null; // Reset error state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLastUpdate.pending, (state) => {
        state.loading = true; // Set loading state to true
        state.error = null; // Reset error state
      })
      .addCase(
        fetchLastUpdate.fulfilled,
        (state, action: PayloadAction<{ data: LastUpdateData }>) => {
          // Change the payload type to
          state.loading = false; // Set loading state to false
          state.data = action.payload?.data; // Store the retrieved
        },
      )
      .addCase(fetchLastUpdate.rejected, (state, action) => {
        state.loading = false; // Set loading state to false
        state.error = action.payload as string; // Set error message
      });
  },
});

// Export actions
export const { clearLastUpdate } = lastUpdateSlice.actions;

// Export the reducer to be used in the store
export default lastUpdateSlice.reducer;
