import { AddInvoicePayment } from '@/api/invoice/payment/createInvoicePayment';
import { DeleteInvoicePayment } from '@/api/invoice/payment/deleteInvoicePayment';
import { GetInvoiceAllPayments } from '@/api/invoice/payment/getInvoiceAllPayments';
import { GetInvoiceSinglePayment } from '@/api/invoice/payment/getInvoiceSinglePayment';
import { UpdateInvoicePayment } from '@/api/invoice/payment/updateInvoicePayment';
import {
  InvoicePaymentFormData,
  InvoiceSinglePaymentType,
} from '@/helpers/types';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { fetchSingleInvoice } from './invoiceThunk';

// Types
type FetchInvoiceAllPaymentsResponse = {
  data: InvoiceSinglePaymentType[];
  status: string;
};

type FetchInvoiceAllPaymentsArgs = {
  invoiceUuid: string;
};

type FetchSingleInvoicePaymentArgs = {
  invoiceUuid: string;
  paymentUuid: string;
};

type AddInvoicePaymentResponse = {
  data: InvoicePaymentFormData;
  status: string;
};

type UpdateInvoicePaymentResponse = {
  data: InvoicePaymentData;
  status: string;
};

export interface InvoicePaymentData extends InvoicePaymentFormData {
  invoiceUuid: string;
}

export interface UpdateInvoicePaymentData extends InvoicePaymentData {
  paymentUuid?: string;
}

type DeleteInvoicePaymentResponse = {
  status: string;
};

type DeleteInvoicePaymentArgs = {
  invoiceUuid: string;
  paymentUuid: string;
};

type FetchInvoicePaymentError = string | SerializedError;

// Fetch all invoice payment
export const fetchInvoiceAllPayments = createAsyncThunk<
  FetchInvoiceAllPaymentsResponse,
  FetchInvoiceAllPaymentsArgs,
  { rejectValue: FetchInvoicePaymentError }
>(
  'invoicePayment/fetchAllInvoicePayments',
  async ({ invoiceUuid }, { rejectWithValue }) => {
    try {
      const response = await GetInvoiceAllPayments(invoiceUuid);
      return response;
    } catch (error: any) {
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      // if (error.response) {
      //   const { message, messages } = error.response.data;
      //   return rejectWithValue({ message, messages });
      // }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Fetch single invoice payment
export const fetchInvoiceSinglePayment = createAsyncThunk<
  InvoiceSinglePaymentType,
  FetchSingleInvoicePaymentArgs,
  { rejectValue: FetchInvoicePaymentError }
>(
  'invoicePayment/fetchInvoiceSinglePayment',
  async ({ invoiceUuid, paymentUuid }, { rejectWithValue }) => {
    try {
      const response = await GetInvoiceSinglePayment(invoiceUuid, paymentUuid);
      return response.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      // if (error.response) {
      //   const { message, messages } = error.response.data;
      //   return rejectWithValue({ message, messages });
      // }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Add invoice payment
export const addInvoicePayment = createAsyncThunk<
  AddInvoicePaymentResponse,
  InvoicePaymentData,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'invoicePayment/addInvoicePayment',
  async (
    newInvoicePaymentData: InvoicePaymentData,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await AddInvoicePayment(newInvoicePaymentData);
      await dispatch(
        fetchInvoiceAllPayments({
          invoiceUuid: newInvoicePaymentData.invoiceUuid,
        }),
      );
      await dispatch(fetchSingleInvoice({ invoiceUuid: newInvoicePaymentData.invoiceUuid }))
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

// Update invoice payment
export const updateInvoicePayment = createAsyncThunk<
  UpdateInvoicePaymentResponse,
  UpdateInvoicePaymentData,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'invoicePayment/updateInvoicePayment',
  async (
    updatedInvoicePaymentData: UpdateInvoicePaymentData,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await UpdateInvoicePayment(updatedInvoicePaymentData);
      await dispatch(
        fetchInvoiceAllPayments({
          invoiceUuid: updatedInvoicePaymentData.invoiceUuid,
        }),
      );
      await dispatch(fetchSingleInvoice({ invoiceUuid: updatedInvoicePaymentData.invoiceUuid }))
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Delete invoice payment
export const deleteInvoicePayment = createAsyncThunk<
  DeleteInvoicePaymentResponse,
  DeleteInvoicePaymentArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'invoicePayment/deleteInvoicePayment',
  async (DeleteInvoiceData, { dispatch, rejectWithValue }) => {
    try {
      const response = await DeleteInvoicePayment(
        DeleteInvoiceData.invoiceUuid,
        DeleteInvoiceData.paymentUuid,
      );
      await dispatch(
        fetchInvoiceAllPayments({
          invoiceUuid: DeleteInvoiceData.invoiceUuid,
        }),
      );
      await dispatch(fetchSingleInvoice({ invoiceUuid: DeleteInvoiceData.invoiceUuid }))
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);
