/* eslint-disable prettier/prettier */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CustomerPortalSingleCompany,
} from "@/helpers/types/customerPortalTypes";
import { fetchSingleCompanyData } from "@/redux/thunk/customer-portal/companyThunk";

interface SingleCompany {
  selectedCompanyData: CustomerPortalSingleCompany;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: SingleCompany = {
  selectedCompanyData: {
    uuid: '',
    name: '',
    team_size: '',
    email_1: '',
    email_2: '',
    phone: '',
    website_url: '',
    country: {
      id: '',
      name: '',
    },
    address: '',
    timezone: {
      id: '',
      name: '',
    },
    date_format: {
      id: '',
      name: '',
    },
    time_format: {
      id: '',
      name: '',
    },
    first_day_of_week: {
      id: '',
      name: '',
    },
    logo_url: {
      id: '',
      name: '',
    },
    tax_id_name: '',
    tax_id_number: '',
    is_individual: '',
    is_active: '',
    social_links: '',
    registered_on: '',
  },
  status: 'idle',
  error: null,
};

const singleCompanySlice = createSlice({
  name: 'single-company',
  initialState,
  reducers: {
    clearSingleCompany(state) {
      state.selectedCompanyData = initialState.selectedCompanyData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleCompanyData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchSingleCompanyData.fulfilled,
        (state, action: PayloadAction<{ data: CustomerPortalSingleCompany }>) => {
          state.selectedCompanyData = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchSingleCompanyData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearSingleCompany } = singleCompanySlice.actions;
export default singleCompanySlice.reducer;
