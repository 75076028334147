import { CustomerNoteType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCustomerAllNotes } from '../thunk/customerNoteThunk';

interface CustomerAllNoteState {
  customerNotes: CustomerNoteType[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: CustomerAllNoteState = {
  customerNotes: [],
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const customerAllNoteSlice = createSlice({
  name: 'customer-all-note',
  initialState,
  reducers: {
    clearCustomerNotes(state) {
      state.customerNotes = initialState.customerNotes;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerAllNotes.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchCustomerAllNotes.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: CustomerNoteType[];
          }>,
        ) => {
          state.status = 'succeeded';
          state.customerNotes = action.payload.data;
        },
      )
      .addCase(fetchCustomerAllNotes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearCustomerNotes } = customerAllNoteSlice.actions;
export default customerAllNoteSlice.reducer;
