import { API_INVOICE, API_METHOD_GET } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { Method } from 'axios';

export const SearchInvoice = async (
  searchTerm: string,
  filteredType: string,
  invoiceCountPerPage: number,
  pageNumber: number,
  filterDateRange: {
    start: string;
    end: string;
  },
  sortBy: string | null,
  sortOrder: string | null,
  selectedCustomer?: string,
) => {
  const method: Method = API_METHOD_GET;

  let url = `${API_INVOICE}?search=${encodeURIComponent(searchTerm)}`;

  url += `&f-status=${filteredType !== undefined ? encodeURIComponent(filteredType) : ''}`;
  url += `&p-size=${invoiceCountPerPage !== undefined ? encodeURIComponent(invoiceCountPerPage) : ''}`;
  url += `&p=${pageNumber !== undefined ? encodeURIComponent(pageNumber) : ''}`;
  if (filterDateRange.start && filterDateRange.end) {
    url += `&f-created_at=${encodeURIComponent(`${filterDateRange.start}:${filterDateRange.end}`)}`;
  }
  if (selectedCustomer) {
    url += `&f-customer=${encodeURIComponent(`${selectedCustomer}`)}`;
  }

  if (sortBy) {
    url += `&sort-by=${encodeURIComponent(`${sortBy}`)}`;
  }

  if (sortOrder) {
    url += `&sort-order=${encodeURIComponent(`${sortOrder}`)}`;
  }

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// quotes/?search=&sort-by=&sort-order=desc&p=1&p-size=10&f-status=0&f-created_at=2024-01-05:2024-12-06
