// api/register.ts
import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_CUSTOMER_PORTAL, API_METHOD_GET } from '@/constants/apiConstants';

type FetchJobByUuidArgs = {
  referenceKey: string;
  uuid: string;
};

export const FetchJobByUuid = async (payload: FetchJobByUuidArgs) => {
  const method: Method = API_METHOD_GET;
  const url =
    API_CUSTOMER_PORTAL + '/' + payload.referenceKey + '/jobs/' + payload.uuid;

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
