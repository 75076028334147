import { AllPurchaseOrdersType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAllPurchaseOrders } from '../thunk/tradeWorkflowThunk';

interface PurchaseOrdersAllState {
    purchaseOrders: AllPurchaseOrdersType[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    fieldErrors?: Record<string, string[]>;
}

const initialState: PurchaseOrdersAllState = {
    purchaseOrders: [],
    status: 'idle',
    error: null,
    fieldErrors: {},
};

const purchaseOrdersAllSlice = createSlice({
    name: 'purchase-orders-all',
    initialState,
    reducers: {
        clearPurchaseOrders(state) {
            state.purchaseOrders = initialState.purchaseOrders;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllPurchaseOrders.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(
                fetchAllPurchaseOrders.fulfilled,
                (
                    state,
                    action: PayloadAction<{
                        data: AllPurchaseOrdersType[];
                    }>,
                ) => {
                    state.status = 'succeeded';
                    state.purchaseOrders = action.payload.data;
                },
            )
            .addCase(fetchAllPurchaseOrders.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    },
});

export const { clearPurchaseOrders } = purchaseOrdersAllSlice.actions;
export default purchaseOrdersAllSlice.reducer;
