// api/register.ts
import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_GET_QUOTES, API_METHOD_DELETE } from '@/constants/apiConstants';

export const DeleteQuoteNoteData = async (
  quoteUuid: string,
  noteuUuid: string,
) => {
  const method: Method = API_METHOD_DELETE;
  const url = API_GET_QUOTES + '/' + quoteUuid + '/notes/' + noteuUuid;

  try {
    const response = await apiCall({
      method,
      url,
    });

    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
