import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAllStaffTracking, SingleStaffTrackingDataType } from '../thunk/staffTrackingThunk';

interface AllStaffTrackingState {
    allStaffTracking: SingleStaffTrackingDataType[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    fieldErrors?: Record<string, string[]>;
}

const initialState: AllStaffTrackingState = {
    allStaffTracking: [],
    status: 'idle',
    error: null,
    fieldErrors: {},
};

const staffTrackingAllSlice = createSlice({
    name: 'tracking-all-staff',
    initialState,
    reducers: {
        clearStaffTracking(state) {
            state.allStaffTracking = initialState.allStaffTracking;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllStaffTracking.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(
                fetchAllStaffTracking.fulfilled,
                (
                    state,
                    action: PayloadAction<{
                        data: SingleStaffTrackingDataType[];
                    }>,
                ) => {
                    state.status = 'succeeded';
                    state.allStaffTracking = action.payload.data;
                },
            )
            .addCase(fetchAllStaffTracking.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    },
});

export const { clearStaffTracking } = staffTrackingAllSlice.actions;
export default staffTrackingAllSlice.reducer;
