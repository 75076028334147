import { Pagination } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QuotesData } from '@/helpers/types/customerPortalTypes';
import { fetchQuotes } from '@/redux/thunk/customer-portal/quoteThunk';
import { searchQuotes } from '@/redux/thunk/customer-portal/quoteThunk';

interface QuoteState {
  quotes: QuotesData[];
  pagination: Pagination;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: QuoteState = {
  quotes: [],
  pagination: {
    total_records: 0,
    current_records: 0,
    record_from: 0,
    record_to: 0,
    current_page: 0,
    total_pages: 0,
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    clearQuotes(state) {
      state.quotes = initialState.quotes;
      state.pagination = initialState.pagination;
      state.status = initialState.status;
      state.error = initialState.error;
      state.fieldErrors = initialState.fieldErrors;
    },
  },
  extraReducers: (builder) => {
    // Fetch quotes
    builder
      .addCase(fetchQuotes.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchQuotes.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: QuotesData[];
            pagination: Pagination;
          }>,
        ) => {
          state.status = 'succeeded';
          state.quotes = action.payload.data;
          state.pagination = action.payload.pagination;
        },
      )
      .addCase(fetchQuotes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    // Search quotes
    builder
      .addCase(searchQuotes.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        searchQuotes.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: QuotesData[];
            pagination: Pagination;
          }>,
        ) => {
          state.status = 'succeeded';
          state.quotes = action.payload.data;
          state.pagination = action.payload.pagination;
        },
      )
      .addCase(searchQuotes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearQuotes } = quoteSlice.actions;
export default quoteSlice.reducer;
