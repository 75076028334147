import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAllReportJobs, ReportJobsDataType } from '../thunk/reportThunk';

interface ReportState {
    jobs: ReportJobsDataType[]
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    fieldErrors?: Record<string, string[]>;
}


const initialState: ReportState = {
    jobs: [],
    status: 'idle',
    error: null,
    fieldErrors: {},
};

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        clearReportAllJobs(state) {
            state.jobs = initialState.jobs;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllReportJobs.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(
                fetchAllReportJobs.fulfilled,
                (
                    state,
                    action: PayloadAction<{
                        data: ReportJobsDataType[];
                    }>,
                ) => {
                    state.status = 'succeeded';
                    state.jobs = action.payload.data;
                },
            )
            .addCase(fetchAllReportJobs.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });

    },
});

export const { clearReportAllJobs } = reportSlice.actions;
export default reportSlice.reducer;
