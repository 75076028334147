import { GetAllJobs } from '@/api/report/getAllJobs';
import { GetJobReport } from '@/api/report/getJobReport';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';

// Types
// Data types
export interface ReportJobsDataType {
    uuid: string
    title: string
    job_number: string
}
// Response types
type FetchAllJobsResponse = {
    status: string
    data: ReportJobsDataType[]
};

type DownloadReportResponse = {
    status: string
    data: {
        link: string
        size: string
        name: string
    }
    message: string
}

// Argument types
type DownloadJobReportArgs = {
    jobUuid: string;
};

type FetchJobsError = string | SerializedError;

// Fetch all jobs
export const fetchAllReportJobs = createAsyncThunk<
    FetchAllJobsResponse,
    void,
    { rejectValue: FetchJobsError }
>('report/fetchReportAllJobs', async (_, { rejectWithValue }) => {
    try {
        const response = await GetAllJobs();
        const data: FetchAllJobsResponse = response;
        return { status: data.status, data: data.data };
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
    }
    return rejectWithValue('An unknown error occurred');
});

// Download report
export const downloadJobReport = createAsyncThunk<
    DownloadReportResponse,
    DownloadJobReportArgs,
    { rejectValue: FetchJobsError }
>('report/downloadJobReport', async ({ jobUuid }, { rejectWithValue }) => {
    try {
        const response = await GetJobReport(jobUuid);
        return response
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
    }
    return rejectWithValue('An unknown error occurred');
});
