import { Pagination } from '@/helpers/types';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import {
  CustomerPortalSingleQuote,
  QuotesData,
} from '@/helpers/types/customerPortalTypes';
import { GetQuotes } from '@/api/customerPortal/quotes/getQuotes';
import { SearchQuotes } from '@/api/customerPortal/quotes/searchQuotes';
import { FetchQuoteByUuid } from '@/api/customerPortal/quotes/fetchQuoteByUuid';
import { UpdateQuoteStatus } from '@/api/customerPortal/quotes/updateQuoteStatus';
import { toast } from 'react-toastify';

type FetchQuotesResponse = {
  data: QuotesData[];
  pagination: Pagination;
};

type FetchQuoteByUuidResponse = {
  data: CustomerPortalSingleQuote;
};

type FetchQuoteByUuidArgs = {
  referenceKey: string;
  uuid: string;
};

type FetchQuotesArgs = {
  referenceKey: string;
};
type SearchQuotesArgs = {
  referenceKey: string;
  searchTerm: string;
  filteredType: number | undefined;
  enquiryCountPerPage: number;
  pageNumber: number;
  filterDateRange: {
    start: string | null;
    end: string | null;
  };
  selectedCustomer?: string;
};

interface UpdateQuoteStatusArgs {
  referenceKey: string;
  uuid: string;
  status: string;
}

type FetchQuoteError = string | SerializedError;
type UpdateQuoteStatusError = {
  message: string;
  messages?: any; // Add any or a specific type for messages if needed
};

// Fetch quotes
export const fetchQuotes = createAsyncThunk<
  FetchQuotesResponse,
  FetchQuotesArgs,
  { rejectValue: FetchQuoteError }
>(
  'customer-portal/quotes/fetchQuotes',
  async ({ referenceKey }, { rejectWithValue }) => {
    try {
      const response = await GetQuotes(referenceKey);
      const data: FetchQuotesResponse = response.data;
      return { data: data.data, pagination: data.pagination };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
    return rejectWithValue('An unknown error occurred');
  },
);

interface UpdateQuoteStatusResponse {
  message(message: any): unknown;

  data: any; // Adjust based on your API's response structure
}

// Search quotes
export const searchQuotes = createAsyncThunk<
  FetchQuotesResponse,
  SearchQuotesArgs,
  { rejectValue: FetchQuoteError }
>(
  'customer-portal/quotes/searchQuotes',
  async (
    {
      referenceKey,
      searchTerm,
      filteredType,
      enquiryCountPerPage,
      pageNumber,
      filterDateRange,
      selectedCustomer,
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await SearchQuotes(
        referenceKey,
        searchTerm,
        filteredType,
        enquiryCountPerPage,
        pageNumber,
        filterDateRange,
        selectedCustomer,
      );
      const data: FetchQuotesResponse = response.data;
      return { data: data.data, pagination: data.pagination };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Fetch Single Quote
export const fetchQuoteByUuid = createAsyncThunk<
  FetchQuoteByUuidResponse,
  FetchQuoteByUuidArgs,
  {
    rejectValue: FetchQuoteError;
  }
>(
  'customer-portal/quotes/viewQuote',
  async (FetchQuoteByUuidArgs, { rejectWithValue }) => {
    try {
      const response = await FetchQuoteByUuid(FetchQuoteByUuidArgs);
      const data: CustomerPortalSingleQuote = response?.data;
      return { data: data };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const updateQuoteStatus = createAsyncThunk<
  UpdateQuoteStatusResponse, // Success response type
  UpdateQuoteStatusArgs, // Request payload type
  { rejectValue: UpdateQuoteStatusError } // Error type for rejection
>(
  'customer-portal/quotes/updateQuoteStatus',
  async (quoteData: UpdateQuoteStatusArgs, { dispatch, rejectWithValue }) => {
    try {
      // Call the API function
      const response = await UpdateQuoteStatus(quoteData);
      toast.success('Quote status updated successfully!');

      // Optionally fetch updated quotes
      //dispatch(fetchQuotes());

      return response.data; // Return response data
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }

      return rejectWithValue({
        message: error.message || 'An unknown error occurred',
      });
    }
  },
);
