import { API_CUSTOMER_PORTAL, API_METHOD_GET } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { Method } from 'axios';

export const SearchRequests = async (
  referenceKey: string,
  searchTerm: string,
  filteredType: number | undefined,
  enquiryCountPerPage: number,
  pageNumber: number,
  filterDateRange: {
    start: string | null;
    end: string | null;
  },
  selectedCustomer?: string,
) => {
  const method: Method = API_METHOD_GET;

  // Construct the base URL
  let url = `${API_CUSTOMER_PORTAL}/${referenceKey}/enquiries?search=${encodeURIComponent(searchTerm)}`;

  // Add filters with empty values if undefined
  url += `&f-status=${filteredType !== undefined ? encodeURIComponent(filteredType) : ''}`;
  url += `&p-size=${enquiryCountPerPage !== undefined ? encodeURIComponent(enquiryCountPerPage) : ''}`;
  url += `&p=${pageNumber !== undefined ? encodeURIComponent(pageNumber) : ''}`;
  if (filterDateRange.start && filterDateRange.end) {
    url += `&f-requested_on=${encodeURIComponent(`${filterDateRange.start}:${filterDateRange.end}`)}`;
  }
  if (selectedCustomer) {
    url += `&f-customer=${encodeURIComponent(`${selectedCustomer}`)}`;
  }

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// quotes/?search=&sort-by=&sort-order=desc&p=1&p-size=10&f-status=0&f-created_at=2024-01-05:2024-12-06
