import { Pagination } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestsData } from '@/helpers/types/customerPortalTypes';
import {
  fetchRequests,
  searchRequests,
} from '@/redux/thunk/customer-portal/requestThunk';

interface RequestState {
  requests: RequestsData[];
  pagination: Pagination;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: RequestState = {
  requests: [],
  pagination: {
    total_records: 0,
    current_records: 0,
    record_from: 0,
    record_to: 0,
    current_page: 0,
    total_pages: 0,
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const requestSlice = createSlice({
  name: 'enquiry',
  initialState,
  reducers: {
    clearRequests(state) {
      state.requests = initialState.requests;
      state.pagination = initialState.pagination;
      state.status = initialState.status;
      state.error = initialState.error;
      state.fieldErrors = initialState.fieldErrors;
    },
  },
  extraReducers: (builder) => {
    // Fetch requests
    builder
      .addCase(fetchRequests.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchRequests.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: RequestsData[];
            pagination: Pagination;
          }>,
        ) => {
          state.status = 'succeeded';
          state.requests = action.payload.data;
          state.pagination = action.payload.pagination;
        },
      )
      .addCase(fetchRequests.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    // Search enquiries
    builder
      .addCase(searchRequests.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        searchRequests.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: RequestsData[];
            pagination: Pagination;
          }>,
        ) => {
          state.status = 'succeeded';
          state.requests = action.payload.data;
          state.pagination = action.payload.pagination;
        },
      )
      .addCase(searchRequests.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearRequests } = requestSlice.actions;
export default requestSlice.reducer;
