import { Pagination } from '@/helpers/types';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import {
  CreateRequestPayload,
  RequestsData,
  CustomerPortalSingleRequest,
} from '@/helpers/types/customerPortalTypes';
import { GetEnquiryRequests } from '@/api/customerPortal/requests/getRequests';
import { SearchRequests } from '@/api/customerPortal/requests/searchRequests';
import { AddRequest } from '@/api/customerPortal/requests/addRequest';
import { FetchRequestByUuid } from "@/api/customerPortal/requests/fetchRequestByUuid";

type FetchRequestsResponse = {
  // data: EnquiriesData;
  data: RequestsData[];
  pagination: Pagination;
};

type FetchRequestByUuidResponse = {
  data: CustomerPortalSingleRequest;
};

type FetchRequestByUuidArgs = {
  referenceKey: string;
  uuid: string;
};

type FetchRequestsArgs = {
  referenceKey: string;
};

type SearchRequestsArgs = {
  referenceKey: string;
  searchTerm: string;
  filteredType: number | undefined;
  enquiryCountPerPage: number;
  pageNumber: number;
  filterDateRange: {
    start: string | null;
    end: string | null;
  };
  selectedCustomer?: string;
};

type FetchRequestError = string | SerializedError;

// Fetch requests
export const fetchRequests = createAsyncThunk<
  FetchRequestsResponse,
  FetchRequestsArgs,
  { rejectValue: FetchRequestError }
>(
  'customer-portal/requests/fetchRequests',
  async ({ referenceKey }, { rejectWithValue }) => {
    try {
      const response = await GetEnquiryRequests(referenceKey);
      const data: FetchRequestsResponse = response.data;
      return { data: data.data, pagination: data.pagination };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
    return rejectWithValue('An unknown error occurred');
  },
);

// Search requests
export const searchRequests = createAsyncThunk<
  FetchRequestsResponse,
  SearchRequestsArgs,
  { rejectValue: FetchRequestError }
>(
  'customer-portal/requests/searchRequests',
  async (
    {
      referenceKey,
      searchTerm,
      filteredType,
      enquiryCountPerPage,
      pageNumber,
      filterDateRange,
      selectedCustomer,
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await SearchRequests(
        referenceKey,
        searchTerm,
        filteredType,
        enquiryCountPerPage,
        pageNumber,
        filterDateRange,
        selectedCustomer,
      );
      const data: FetchRequestsResponse = response.data;
      return { data: data.data, pagination: data.pagination };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Add Request
export const addRequest = createAsyncThunk<
  CreateRequestPayload, // Return type
  CreateRequestPayload, // Argument type
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'customer-portal/requests/addRequest',
  async (newRequest, { rejectWithValue }) => {
    try {
      const response = await AddRequest(newRequest);
      return response.data; // Should match CreateRequestPayload
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

// Fetch Single Request
export const fetchRequestByUuid = createAsyncThunk<
  FetchRequestByUuidResponse,
  FetchRequestByUuidArgs,
  {
    rejectValue: FetchRequestError;
  }
>(
  'customer-portal/requests/viewRequest',
  async (FetchRequestByUuidArgs, { rejectWithValue }) => {
    try {
      const response = await FetchRequestByUuid(FetchRequestByUuidArgs);
      const data: CustomerPortalSingleRequest = response?.data;
      return { data: data };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);
