import { API_CUSTOMER_PORTAL, API_METHOD_POST } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';
import { CreateRequestPayload } from '@/helpers/types/customerPortalTypes';

export const AddRequest = async (requestValues: CreateRequestPayload) => {
  const method: Method = API_METHOD_POST;
  const url =
    API_CUSTOMER_PORTAL + '/' + requestValues.referenceKey + '/enquiries';
  const data = requestValues;

  const jsonString = JSON.stringify(data);
  const additionalHeaders = { hash: generateHash(jsonString) };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
