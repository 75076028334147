/* eslint-disable prettier/prettier */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerPortalJob, QuotesData } from "@/helpers/types/customerPortalTypes";
import { fetchCustomerPortalJobs, searchCustomerPortalJobs } from "@/redux/thunk/customer-portal/jobThunk";
import { Pagination } from "@/helpers/types";
import { fetchQuotes } from "@/redux/thunk/customer-portal/quoteThunk";

interface CustomerPortalJobState {
    customerPortalJobs: CustomerPortalJob[];
    pagination: Pagination;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    fieldErrors?: Record<string, string[]>;
}

const initialState: CustomerPortalJobState = {
    customerPortalJobs: [],
    pagination: {
        total_records: 0,
        current_records: 0,
        record_from: 0,
        record_to: 0,
        current_page: 0,
        total_pages: 0,
    },
    status: 'idle',
    error: null,
    fieldErrors: {},
};

const customerPortalJobsSlice = createSlice({
    name: 'CustomerPortalJob',
    initialState,
    reducers: {
        clearCustomerPortalJobs(state) {
            state.customerPortalJobs = initialState.customerPortalJobs;
            state.pagination = initialState.pagination;
        },
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchCustomerPortalJobs.pending, (state) => {
              state.status = 'loading';
              state.error = null;
          })
          .addCase(
            fetchCustomerPortalJobs.fulfilled,
            (
              state,
              action: PayloadAction<{
                  data: CustomerPortalJob[];
                  pagination: Pagination;
              }>,
            ) => {
                state.status = 'succeeded';
                state.customerPortalJobs = action.payload.data;
                state.pagination = action.payload.pagination;
            },
          )
          .addCase(fetchQuotes.rejected, (state, action) => {
              state.status = 'failed';
              state.error = action.payload as string;
          });

      // Search jobs
      builder
        .addCase(searchCustomerPortalJobs.pending, (state) => {
          state.status = 'loading';
          state.error = null;
        })
        .addCase(
          searchCustomerPortalJobs.fulfilled,
          (
            state,
            action: PayloadAction<{
              data: CustomerPortalJob[];
              pagination: Pagination;
            }>,
          ) => {
            state.status = 'succeeded';
            state.customerPortalJobs = action.payload.data;
            state.pagination = action.payload.pagination;
          },
        )
        .addCase(searchCustomerPortalJobs.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload as string;
        });
    },
});

export const { clearCustomerPortalJobs } = customerPortalJobsSlice.actions;
export default customerPortalJobsSlice.reducer;
