import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Pagination } from '@/helpers/types';

import {
  createPurchaseOrder,
  fetchPurchaseOrders,
  searchPurchaseOrders,
  updatePurchaseOrder,
} from '@/redux/thunk/purchaseOderThunk';
import { PurchaseOrders } from '@/helpers/types/puchaseOrderTypes';

interface PurchaseOrderState {
  purchaseOrders: PurchaseOrders[];
  pagination: Pagination;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: PurchaseOrderState = {
  purchaseOrders: [],
  pagination: {
    total_records: 0,
    current_records: 0,
    record_from: 0,
    record_to: 0,
    current_page: 0,
    total_pages: 0,
  },
  status: 'idle',
  error: null,
};

// Define the custom broadcast action
export const broadcastPurchaseOrdersUpdated = createAction<PurchaseOrders[]>(
  'BROADCAST_PURCHASE_ORDERS_UPDATED',
);

const purchaseOrderSlice = createSlice({
  name: 'purchaseOrder',
  initialState,
  reducers: {
    clearPurchaseOrders(state) {
      state.pagination = initialState.pagination;
      state.purchaseOrders = initialState.purchaseOrders;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPurchaseOrders.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchPurchaseOrders.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: PurchaseOrders[];
            pagination: Pagination;
          }>,
        ) => {
          state.purchaseOrders = action.payload.data;
          state.pagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchPurchaseOrders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })

      //Search Purchase Orders
      .addCase(searchPurchaseOrders.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        searchPurchaseOrders.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: PurchaseOrders[];
            pagination: Pagination;
          }>,
        ) => {
          state.purchaseOrders = action.payload.data;
          state.pagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(searchPurchaseOrders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })

      // Delete Purchase Order
      // .addCase(deletePurchaseOrder.pending, (state) => {
      //   state.status = 'loading';
      // })
      // .addCase(deletePurchaseOrder.fulfilled, (state) => {
      //   state.status = 'succeeded';
      // })
      // .addCase(deletePurchaseOrder.rejected, (state, action) => {
      //   state.status = 'failed';
      //   state.error = action.payload as string;
      // })

      // Handle create Purchase Order
      .addCase(createPurchaseOrder.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.fieldErrors = {};
      })
      .addCase(
        createPurchaseOrder.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = 'succeeded';
          state.error = null; // Clear any previous errors
          state.fieldErrors = {}; // Clear field errors on success
        },
      )
      .addCase(createPurchaseOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as unknown as string;
      })

      // Handle update Purchase Order
      .addCase(updatePurchaseOrder.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.fieldErrors = {};
      })
      .addCase(
        updatePurchaseOrder.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = 'succeeded';
          state.error = null; // Clear any previous errors
          state.fieldErrors = {}; // Clear field errors on success
        },
      )
      .addCase(updatePurchaseOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as unknown as string;
      });

    // ✅ Handle cross-tab syncing with a proper action creator
    builder.addCase(broadcastPurchaseOrdersUpdated, (state, action) => {
      state.purchaseOrders = action.payload;
    });
  },
});

export const { clearPurchaseOrders } = purchaseOrderSlice.actions;
export default purchaseOrderSlice.reducer;
