import { SingleProductType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSingleProduct } from '../thunk/productThunk';

interface ProductSingleState {
    product: SingleProductType;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    fieldErrors?: Record<string, string[]>;
}

const initialState: ProductSingleState = {
    product: {
        uuid: '',
        company: {
            id: '',
            name: ''
        },
        item_type: 0,
        item_type_label: '',
        name: '',
        item_code: '',
        description: '',
        cost: 0,
        unit_price: 0,
        mark_up: 0,
        image_url: {
            link: '',
            size: '',
            name: ''
        },
        is_tax_exempted: 0,
        is_tax_exempted_label: '',
    },
    status: 'idle',
    error: null,
    fieldErrors: {},
}

const productSingleSlice = createSlice({
    name: 'product-single-state',
    initialState,
    reducers: {
        clearProduct(state) {
            state.product = initialState.product;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSingleProduct.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(
                fetchSingleProduct.fulfilled,
                (state, action: PayloadAction<SingleProductType>) => {
                    state.status = 'succeeded';
                    state.product = action.payload;
                },
            )
            .addCase(fetchSingleProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    },
});

export const { clearProduct } = productSingleSlice.actions;
export default productSingleSlice.reducer;
