import { AllVariationsType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAllVariations } from '../thunk/tradeWorkflowThunk';

interface VariationAllState {
    variations: AllVariationsType[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    fieldErrors?: Record<string, string[]>;
}

const initialState: VariationAllState = {
    variations: [],
    status: 'idle',
    error: null,
    fieldErrors: {},
};

const variationAllSlice = createSlice({
    name: 'variation-all',
    initialState,
    reducers: {
        clearVariations(state) {
            state.variations = initialState.variations;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllVariations.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(
                fetchAllVariations.fulfilled,
                (
                    state,
                    action: PayloadAction<{
                        data: AllVariationsType[];
                    }>,
                ) => {
                    state.status = 'succeeded';
                    state.variations = action.payload.data;
                },
            )
            .addCase(fetchAllVariations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    },
});

export const { clearVariations } = variationAllSlice.actions;
export default variationAllSlice.reducer;
