import { API_METHOD_GET, API_VARIATIONS } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { Method } from 'axios';

export const GetSingleSchedule = async (jobUuid: string, serviceUuid: string) => {
    const method: Method = API_METHOD_GET;
    const url = API_VARIATIONS + `/${jobUuid}/service-schedules/${serviceUuid}`;

    try {
        const response = await apiCall({
            method,
            url,
        });
        return response;
    } catch (error) {
        throw error;
    }
};
