import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_JOB_REPORT, API_METHOD_GET } from '@/constants/apiConstants';

export const GetJobReport = async (jobUuid: string) => {
    const method: Method = API_METHOD_GET;
    const url = API_JOB_REPORT + `/${jobUuid}/report`;

    try {
        const response = await apiCall({
            method,
            url,
        });

        return response;
    } catch (error) {
        throw error;
    }
};
