/* eslint-disable prettier/prettier */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SingleQuoteByUuid } from '@/helpers/types/quoteTypes';
import { fetchQuoteByUuid, fetchQuoteDownloadLink, FetchQuoteDownloadLinkResponse } from '../thunk/quoteThunk';

interface SingleQuote {
  selectedQuoteData: SingleQuoteByUuid;
  downloadLink: {
    link: string;
    size: string;
    name: string;
  } | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: SingleQuote = {
  selectedQuoteData: {
    uuid: '',
    company_id: '',
    customer: {
      id: '',
      name: '',
      address: '',
      phone: '',
      email: '',
    },
    created: '',
    customer_site: {
      address: '',
      name: '',
      uuid: '',
    },
    quote_number: '',
    rating: 0,
    title: '',
    client_message: '',
    disclaimer: '',
    tax_rate: 0,
    tax_rate_id: null,
    subtotal: 0,
    discount: 0,
    discount_percentage : 0,
    tax_amount: 0,
    total: 0,
    deposit_required: 0,
    deposit_required_percentage: 0,
    status: 0,
    cost: 0,
    products: [],
    notes: [],
    margin_value: '',
    margin_percentage: '',
    expenses: [],
    labours: []
  },
  downloadLink: null,
  status: 'idle',
  error: null,
};

const singleQuoteSlice = createSlice({
  name: 'sinle-quote',
  initialState,
  reducers: {
    clearSingleQuote(state) {
      state.selectedQuoteData = initialState.selectedQuoteData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuoteByUuid.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchQuoteByUuid.fulfilled,
        (state, action: PayloadAction<{ data: SingleQuoteByUuid }>) => {
          state.selectedQuoteData = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchQuoteByUuid.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    builder
      .addCase(fetchQuoteDownloadLink.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchQuoteDownloadLink.fulfilled,
        (state, action: PayloadAction<FetchQuoteDownloadLinkResponse>) => {
          state.downloadLink = action.payload.data; // Store data object
          state.status = 'succeeded';
        },
      )
      .addCase(fetchQuoteDownloadLink.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearSingleQuote } = singleQuoteSlice.actions;
export default singleQuoteSlice.reducer;
