'use client';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NextUIProvider } from '@nextui-org/system';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { ThemeProviderProps } from 'next-themes/dist/types';
import { RootState } from '@/redux/store';

interface LocaleProviderProps {
  children: React.ReactNode;
  themeProps?: ThemeProviderProps;
}

const LocaleProvider: React.FC<LocaleProviderProps> = ({
  children,
  themeProps,
}) => {
  const { company } = useSelector((state: RootState) => state.auth);

  // const company = useSelector((state: RootState) => state.auth.company);
  const [locale, setLocale] = useState<string | null>(null);

  const mapDateFormatWithLocale = (format?: string) => {
    switch (format) {
      case 'dd/MM/yyyy':
        return 'en-GB'; // UK
      case 'MM/dd/yyyy':
        return 'en-US'; // US
      case 'yyyy-MM-dd':
        return 'en-SE'; // Sweden
      case 'MMM dd, yyyy':
        return 'en-US'; // US format
      default:
        return 'en-US'; // Default to US if no match
    }
  };

  useEffect(() => {
    console.log('Company Data:', company);

    if (company?.date_format?.frontend_format) {
      const detectedLocale = mapDateFormatWithLocale(
        company?.date_format?.frontend_format,
      );
      console.log('Detected Locale:', detectedLocale);
      setLocale(detectedLocale);
    } else {
      setLocale('en-US'); // Default locale
    }
  }, [company]);

  // ✅ Ensure `NextUIProvider` only loads when `locale` is set
  if (!locale) return null; // Prevents UI flickering

  return (
    <NextUIProvider locale={locale}>
      <NextThemesProvider
        defaultTheme="light"
        attribute="class"
        {...themeProps}
      >
        {children}
      </NextThemesProvider>
    </NextUIProvider>
  );
};

export default LocaleProvider;
