import { API_CUSTOMER_PORTAL, API_METHOD_GET } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { Method } from 'axios';

export const GetQuotes = async (referenceKey: string) => {
  const method: Method = API_METHOD_GET;
  const url = API_CUSTOMER_PORTAL + '/' + referenceKey + '/quotes';

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
