import { ScheduleType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSingleSchedule } from '../thunk/tradeWorkflowThunk';

interface SchedulesSingleState {
    schedule: ScheduleType;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    fieldErrors?: Record<string, string[]>;
}

const initialState: SchedulesSingleState = {
    schedule: {
        uuid: '',
        date: '',
        note: '',
        status: 0
    },
    status: 'idle',
    error: null,
    fieldErrors: {},
}

const schedulesSingleSlice = createSlice({
    name: 'schedule-single-state',
    initialState,
    reducers: {
        clearSchedule(state) {
            state.schedule = initialState.schedule;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSingleSchedule.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(
                fetchSingleSchedule.fulfilled,
                (state, action: PayloadAction<ScheduleType>) => {
                    state.status = 'succeeded';
                    state.schedule = action.payload;
                },
            )
            .addCase(fetchSingleSchedule.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    },
});

export const { clearSchedule } = schedulesSingleSlice.actions;
export default schedulesSingleSlice.reducer;
